import { Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';

interface PbStepImageProps {
  desktopImage: string;
  mobileImage: string;
  objectPosition?: string;
}

const PREFIX = 'PbStepperImage';

const classes = {
  desktopImage: `${PREFIX}-desktopImage`,
  gridContainer: `${PREFIX}-gridContainer`,
};

export const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.desktopImage}`]: {
    objectFit: 'cover',
    overflow: 'hidden',
    height: '100vh',
    backgroundColor: theme.palette.primary.main,
    position: 'fixed',
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
    [theme.breakpoints.down('lg')]: {
      width: '33%',
    },
  },
}));

const PbStepperImage: FunctionComponent<PbStepImageProps> = ({
  desktopImage,
  mobileImage,
  objectPosition = 'top center',
}) => {
  const theme = useTheme();
  const MEDIAQUERY_smUP = useMediaQuery(theme.breakpoints.up('sm'));
  const { project } = useParams();

  return (
    <>
      {MEDIAQUERY_smUP ? (
        <StyledGrid
          item
          xs={12}
          sm={4}
          lg={6}
          style={{
            overflowY: 'hidden',
            height: MEDIAQUERY_smUP ? '100vh' : '',
          }}
        >
          <img
            className={classes.desktopImage}
            style={{
              objectPosition:
                project === 'amex' || project === 'amexv2'
                  ? 'center center'
                  : objectPosition,
            }}
            src={desktopImage}
          />
        </StyledGrid>
      ) : (
        <Grid item xs={12}>
          <img
            style={{
              width: '100%',
              display: 'flex',
            }}
            src={mobileImage}
          />
        </Grid>
      )}
    </>
  );
};

export default PbStepperImage;
